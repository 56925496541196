<template>
  <div class="wansheng">
    <img src="https://static1.kaixinyf.cn/img/lza62b033adde617832005187.png" alt="logo" />
    <h3>你的好友</h3>
    <p>邀请你来<span> 快音 </span>听歌看视频</p>
    <button id="wan-sheng-download-button" @click="handleDownload">打开/下载 快音</button>
  </div>
</template>

<script>
  import InitUmLink from '@/utils/init-ulink';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'WanShengLanding',
    components: {},
    props: {},
    data() {
      return {
        img: require('@/assets/images/common/private_icon.png'),
      };
    },
    computed: {
      ...mapGetters(['traceId']),
    },
    watch: {},
    created() {},
    mounted() {
      // 初始化友盟
      this.initULink();
    },
    methods: {
      ...mapMutations('share/contentLanding', ['SET_UM_LINK']),
      initULink() {
        const selectorStr = '#wan-sheng-download-button';

        const umLink = new InitUmLink({
          selector: selectorStr,
          linkId: 'usr1dh0qovfqs2h1',
          pageTitle: '万声内容分享落地页',
          clipboardText: '',
          traceId: this.traceId,
          ext: { id: this.$route.query.music_code },
        });
        this.SET_UM_LINK(umLink);
      },
      /**
       * @description: 跳转商店或者歌单页
       */
      handleDownload() {
        this.$track({
          page_title: `万声分享落地页`,
          element_name: '打开/下载',
          remarks: this.traceId,
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .wansheng {
    position: relative;
    min-height: 100vh;
    img {
      position: absolute;
      top: 2.42rem;
      left: 1.04rem;
      width: 1.4rem;
      height: 1.4rem;
    }
    h3 {
      position: absolute;
      top: 4.16rem;
      left: 1.06rem;
      color: #333333;
      font-size: 0.42rem;
    }
    p {
      position: absolute;
      top: 4.78rem;
      left: 1.06rem;
      color: #bbbbbb;
      font-size: 0.32rem;
      font-weight: 500;
      span {
        color: #e02333;
      }
    }
    button {
      position: absolute;
      top: 8.5rem;
      left: 0.92rem;
      width: 5.66rem;
      height: 1rem;
      background: #fa3123;
      border-radius: 0.5rem;
      border: none;
      color: #ffffff;
      font-size: 0.36rem;
      font-weight: 600;
    }
  }
</style>
